import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../components/login/login.vue'
import Home from '../components/home.vue'
import KnowLayout from '@/components/knowledge/knowledgeList/layout.vue'
import register from '../components/register'
import store from '@/store/index'
import iView from 'iview'
Vue.use(iView)

Vue.use(VueRouter)
// 解决重复点击菜单栏报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
// const originalReplace = VueRouter.prototype.replace;
// VueRouter.prototype.replace = function replace(location) {
//   return originalReplace.call(this, location).catch(err => err);
// };

const router = new VueRouter({
  mode: 'hash',
  routes: [
    {
      path: '/',
      name: 'lead',
      component: () => import('@/components/lead/index.vue'),
    },
    { path: '/login', name: 'login', component: login },
    {
      path: '/Terms-of-Service',
      name: 'Service',
      component: () => import('@/components/login/components/TermService.vue'),
    },
    {
      path: '/Privacy-Policy',
      name: 'Privacy',
      component: () =>
        import('@/components/login/components/PrivacyPolicy.vue'),
    },
    {
      path: '/konwledge',
      redirect: '/konwledge/cosmological',
      component: KnowLayout,
      children: [
        {
          path: '/konwledge/cosmological',
          name: 'know_list',
          component: () =>
            import('@/components/knowledge/knowledgeList/list.vue'),
        },
        {
          path: '/konwledge/information',
          name: 'know_info',
          component: () =>
            import('@/components/knowledge/knowledgeList/detail.vue'),
        },
      ],
    },
    // {
    //   path: '/test',
    //   component: Home,
    //   children: [
    //     {
    //       path: '',
    //       component: () => import('@/components/performance/fault/index.vue'),
    //       name: 'performancefault',
    //       meta: { title: '设备故障分析' }
    //     }
    //   ]
    // },
    {
      path: '/home',
      component: Home,
      redirect: '/page',
    },
    {
      path: '/404',
      component: Home,
      children: [
        {
          path: '',
          component: () => import('@/components/404.vue'),
          name: '404',
          meta: { title: 'Not Found' },
        },
      ],
    },
    { path: '/register', component: register },
  ],
})

const whiteList = [
  '/login',
  '/',
  '/register',
  '/konwledge/cosmological',
  '/konwledge/information',
  '/Terms-of-Service',
  '/Privacy-Policy',
]
router.beforeEach((to, from, next) => {
  const tokenStr = sessionStorage.getItem('token')
  if (tokenStr) {
    /* has token */
    // if (to.path === '/login') {
    //   next({ path: '/home' })
    // } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      if (!store.state.userInfo) {
        iView.LoadingBar.config({
          color: '#5cb85c',
          height: 2,
        })
        iView.LoadingBar.start()
        // 判断当前用户是否已拉取完user_info信息
        store
          .dispatch('GetInfo')
          .then((res) => {
            store.dispatch('GenerateRoutes', res).then((accessRoutes) => {
              accessRoutes.push({ path: '*', redirect: '/404' })
              accessRoutes.forEach((route) => {
                router.addRoute(route)
              })
              next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
              iView.LoadingBar.finish()
            })
          })
          .catch(() => {
            console.error('用户信息获取失败！！')
            iView.LoadingBar.error()
            store
              .dispatch('LogOut')
              .then(() => {
                next({ path: '/' })
              })
              .catch((err) => {
                console.error('Logout Error:' + err)
                next({ path: '/login' })
              })
          })
      } else {
        next()
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next('/') // 否则全部重定向到前导页
    }
  }
})

export default router
