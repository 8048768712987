import axios from 'axios'
import store from '@/store/index.js'
import { Message } from 'iview'
import { Notification, Loading } from 'element-ui'
import { saveAs } from 'file-saver'
export const baseURL =
  process.env.NODE_ENV === 'development'
    ? 'https://peam.he-mes.com/api'
    : 'https://peam.he-mes.com/api'
// http://192.168.1.14:8000/api
const request = axios.create({
  baseURL,
  timeout: 10000,
})

// let loadingInstance

// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    // loadingInstance = Loading.service({
    //   lock: true,
    //   background: 'rgba(255, 255, 255, 0.3)',
    // })
    // 在发送请求之前做些什么
    config.headers['Content-Type'] =
      config.headers['Content-Type'] || 'application/json'
    if (sessionStorage.getItem('token')) {
      config.headers.Authorization = sessionStorage.getItem('token')
    }
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
    // loadingInstance.close()
    if (response.data.status === 400) {
      Notification.error({
        title: '错误',
        message: response.data.detail,
      })
    }
    // 对响应数据做点什么

    return response
  },
  function (error) {
    const { message } = error
    // loadingInstance.close()
    const { status } = error.request
    if (message.includes('timeout')) {
      Message.error('系统接口请求超时,请重试')
      return Promise.reject()
    }
    if (status === 403) {
      // Message.error('token失效，请重新登录')
      Notification.error({
        title: '错误',
        message: 'token失效，请重新登录',
      })
      store.dispatch('removeUserInfo')
      // 重新跳转到login页面
      return
    }
    if (status >= 404) {
      Message.error('网络错误，请联系管理员！！')
      return Promise.reject()
    }
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)

// 通用下载方法
export function download(url, params, filename) {
  const downloadLoadingInstance = Loading.service({
    text: '正在下载数据，请稍候',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  return request
    .post(url, params, {
      responseType: 'blob',
    })
    .then(async ({ data }) => {
      try {
        const resText = await data.text()
        const rspObj = JSON.parse(resText)
        Message.error(rspObj)
      } catch {
        const blob = new Blob([data])
        saveAs(blob, filename)
      }
      downloadLoadingInstance.close()
    })
    .catch((r) => {
      Message.error('下载文件出现错误，请联系管理员！')
      downloadLoadingInstance.close()
    })
}

export default request
